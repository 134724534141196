import {StyleSheet} from 'aphrodite';
const styles = StyleSheet.create({
    wrapper:{
        marginTop: '100px',
        width: '770px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});
export default styles;