import {StyleSheet} from 'aphrodite';
const styles = StyleSheet.create({
    logo_wrapper:{
        width: "700px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '205px'
    }
});
export default styles;