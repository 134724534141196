import { StyleSheet } from "aphrodite";
const styles = StyleSheet.create({
    text3_t:{
        fontFamily: "SFUIDisplay-Light",
        fontWeight: 300,
        lineHeight: '27px',
        fontSize: '15px'
    },
    text3_wrapper:{
        marginBottom: "60px"
    }
});
export default styles;
