import { StyleSheet } from "aphrodite";
const styles = StyleSheet.create({
    text1_wrapper:{
        marginTop: '50px',
        textAlign: "center",
        color: "#acacac",
        fontFamily: "SFUIDisplay-Light",
        fontSize: "13px",
        fontWeight: 300,
        lineHeight: "27px"
    }
});
export default styles;
