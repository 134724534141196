import {StyleSheet} from 'aphrodite'
const styles = StyleSheet.create({
 logo_wrapper:{
     width: "100%",
     marginTop: '100px'
 },
 logo_img:{
     width: '100%'
 }
})
export default styles