const arr = [
    {name: "Robb Report Kazakhstan", link: "https://robb.report/"},
    {name: "Forbes Kazakhstan", link: "https://forbes.kz/"},
    {name: "L’Officiel Kazakhstan", link: "http://lofficiel.kz/"},
    {name: "Buro 24/7", link: "https://www.buro247.kz/"},
    {name: "Cosmo.kz", link: "https://cosmo.kz/"},
    {name: "Бизнес и Власть", link: "http://and.kz/"},
    {name: "Kapital.kz", link: "https://kapital.kz/"},
    {name: "Informburo", link: "https://informburo.kz/"},
    {name: "Kursiv.kz", link: "https://kursiv.kz/"},
]
export default arr