import {StyleSheet} from 'aphrodite';
const styles = StyleSheet.create({
    wrapper:{
        width: "700px",
        marginTop: "200px",
        fontFamily: 'SFUIDisplay-Light',
        lineHeight: "27px",
        fontWeight: 300,
        fontSize: '20px'
    }
});
export default styles;