const arr =[
    {id: 0, name_ru: "Xerox",name_en:"Xerox", feedback_ru: "Отличительная черта работы агентства “Тайный советник” - высокий профессионализм и организованность сотрудников. За пять лет сотрудничества мы провели более 30-ти конференций для партнеров, разместили более 900 публикаций в СМИ, организовали более 10 событий для прессы.", feedback_en:"Distinctive features of Tayniy Sovetnik agency’s work are high professionalism and self-discipline of employees. During the five years of cooperation, we held more than 30 conferences for partners, placed over 900 publications in the media, and organized more than 10 events for the press."},
    {id: 1, name_ru: "The Ritz Carlton, Almaty", name_en:"The Ritz Carlton, Almaty", feedback_ru: "“Тайный советник” показал себя как опытный партнер, на которого можно положиться для решения самых сложных задач. Все совместные проекты, такие как построение взаимоотношений со СМИ, организация мероприятия и налаживание коммуникаций, позволили достичь поставленных целей", feedback_en: "Tayniy Sovetnik agency proved to be an experienced partner you can rely on for solving the most challenging tasks. All joint projects, such as building relationships with media, organizing events and establishing communications, allowed us to achieve the business and communications goals."},
    {id: 2, name_ru: "Samsung Electronics", name_en:"Samsung Electronics", feedback_ru: "В течение пяти лет “Тайный советник” оказывал информационную поддержку запусков новых продуктов, проводил PR-акции и разрабатывал партнерские мероприятия. В настоящее время мы продолжаем сотрудничество с ТОО “Тайный советник”. Грамотный подход и внимательное отношение к пожеланиям заказчика позволяют рекомендовать ТОО “Тайный советник” как надежного партнера.", feedback_en: "For five years, Tayniy Sovetnik agency provided information support for new product launches, designed and ran PR campaigns and developed partnership events. We continue to cooperate with Tayniy Sovetnik. Such valuable qualities of the agency as competent approach and attentive attitude to the customer's wishes allow us to recommend it as a reliable partner."},
    {id: 3, name_ru: "Eurasian Bank", name_en:"Eurasian Bank", feedback_ru: "Перед агентством стояла задача провести аудит внутренних каналов связи. Рекомендации “Тайного советника” легли в основу оптимизации интернет-портала и сыграли важную роль в увеличении вовлеченности сотрудников в общение внутри Банка.", feedback_en: "The agency was tasked to audit the internal communication channels. The recommendations of Tayniy Sovetnik agency became the basis for optimizing the Internet portal and played an important role in increasing the involvement of employees in communication within the Bank. "},
    {id: 4, name_ru: "Акция “Узнай свое давление”", name_en:'“Check your blood pressure” campaign',  feedback_ru: "Главный кардиолог Алматы и главный врач НИИ кардиологии и внутренних болезней Минздрава РК выражает благодарность за отличную работу по республиканскому проекту “Узнай свое давление”. Благодаря коммуникационной кампании жителей 16 городов Казахстана проинформировали о проблеме гипертензии и получили возможность обратиться к врачам за профессиональной помощью!", feedback_en: "The chief cardiologist of Almaty and the chief physician of the Research Institute of Cardiology and Internal Diseases of the Ministry of Health of the Republic of Kazakhstan express gratitude for the excellent work on the republican project “Check your blood pressure”. Thanks to the communication campaign, residents of 16 cities of Kazakhstan were informed about the problem of hypertension and had the chance to get professional help!"},
    {id: 5, name_ru: "Каркаралинский Государственный Национальный Природный Парк", name_en:"Karkaraly State National Natural Park", feedback_ru: "Каркаралинский Государственный Национальный Природный Парк выражает благодарность за поддержку идеи реконструкций Музея Природы. Надеемся, что наше сотрудничество будет развиваться и поможет сохранению уникальной природы.", feedback_en:"Karkaraly State National Natural Park expresses gratitude for the support of the idea of the Museum of Nature reconstruction. We hope that our cooperation will develop and help preserve our unique natural environment."},
    {id: 6, name_ru: "InvestFunds", name_en:"InvestFunds", feedback_ru: "Выражаем признательность агентству “Тайный советник” за профессиональный консалтинг и оперативность в решении задач. Стратегический подход, неординарные предложения и профессиональные качества - все это делает работу агентства источником ярких и эффективных решений.", feedback_en:"We express gratitude to Tayniy Sovetnik agency for professional consulting and efficiency in solving tasks. A strategic approach, extraordinary ideas and professional qualities - all these qualities make the agency’s work a source of bright and effective solutions.  "},
    {id: 7, name_ru: "Астанинский экономический форум", name_en:"Astana Economic Forum", feedback_ru: "Министерство национальной экономики РК выражает благодарность ТОО “Тайный советник” за оказание спонсорской помощи и активное участие в проведении Астанинского экономического форума.", feedback_en:"The Ministry of National Economy of the Republic of Kazakhstan is grateful to Tayniy Sovetnik for providing sponsorship and active participation in the Astana Economic Forum."}
]
export default arr