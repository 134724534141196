import {StyleSheet} from 'aphrodite';
const styles = StyleSheet.create({
    wrap:{
        padding: '20px',
        fontFamily: "SFUIDisplay-Light",
        fontWeight: 300,
        fontSize: "15px",
        lineHeight: "27px"
    }
});
export default styles;